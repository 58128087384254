import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./projects.css"
import "animate.css/animate.min.css"
const slugify = require("slugify")

class Projects extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tag: "All",
      activeClass: "",
      activeIndex: 0,
    }
    this.handleClick = this.handleClick.bind(this)
  }
  handleClick(index, e) {
    this.setState({
      tag: e.target.innerText,
      activeClass: "animated fadeInUp",
      activeIndex: index,
    })
  }
  render() {
    const { tag, activeClass, activeIndex } = this.state
    const { data } = this.props
    const projects = data.allSanityProject.group
    const filterProject = projects.filter(item => item.fieldValue === tag)
    console.log(filterProject)
    return (
      <Layout>
        <SEO
          title="JKA Design Colorado Projects"
          description="JKA Design’s project highlights showcasing the custom house designs and home remodeling, renovation, and addition work successfully completed."
        />
        <div class="page-headline">
          <div class="container">
            <div class="section-heading text-center">
              <h1>
                <strong>PROJECTS</strong>
              </h1>
            </div>
          </div>
        </div>
        <div className="page-content">
          <div className="container">
            <nav id="filter-container">
              <ul className="list-unstyled filter-tags">
                <li>
                  <button
                    type="button"
                    className={activeIndex === 0 ? "btn-tag-active" : null}
                    onClick={this.handleClick.bind(this, 0)}
                  >
                    All
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className={activeIndex === 1 ? "btn-tag-active" : null}
                    onClick={this.handleClick.bind(this, 1)}
                  >
                    Custom & Luxury Residential
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className={activeIndex === 2 ? "btn-tag-active" : null}
                    onClick={this.handleClick.bind(this, 2)}
                  >
                    Other Consulting & Services
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className={activeIndex === 3 ? "btn-tag-active" : null}
                    onClick={this.handleClick.bind(this, 3)}
                  >
                    Remodel, Renovation or Addition
                  </button>
                </li>
              </ul>
            </nav>
            <h2 className="pt-5 pb-3">
              JKA Design has worked on various interesting projects over the
              years. These are the highlights.
            </h2>
            <div className="row mt-4">
              {tag === "All" || tag === "all"
                ? data.allSanityProject.edges.map(item => (
                    <div key={item.node.id} className="col-md-4 mb-5">
                      <div className={`project-item ${activeClass}`}>
                        <div className="project-item-img">
                          <Link
                            to={`portfolio-item/${slugify(
                              item.node.title.toLowerCase()
                            )}`}
                          >
                            <img
                              src={item.node.mainImage.asset.fixed.src}
                              alt={
                                item.node.mainImage.alt !== null
                                  ? item.node.mainImage.alt
                                  : "project image"
                              }
                            />
                          </Link>
                        </div>
                        <div className="project-item-title px-4 py-3">
                          <h4>
                            <Link
                              to={`portfolio-item/${slugify(
                                item.node.title.toLowerCase()
                              )}`}
                            >
                              {item.node.title}
                            </Link>
                          </h4>
                        </div>
                      </div>
                    </div>
                  ))
                : filterProject[0].edges.map(item => {
                    return (
                      <div key={item.node.id} className="col-md-4 mb-4">
                        <div className={`project-item ${activeClass}`}>
                          <div className="project-item-img">
                            <Link
                              to={`portfolio-item/${slugify(
                                item.node.title.toLowerCase()
                              )}`}
                            >
                              <img
                                src={item.node.mainImage.asset.fixed.src}
                                alt={
                                  item.node.mainImage.alt !== null
                                    ? item.node.mainImage.alt
                                    : "project image"
                                }
                              />
                            </Link>
                          </div>
                          <div className="project-item-title">
                            <h4>
                              <Link
                                to={`portfolio-item/${slugify(
                                  item.node.title.toLowerCase()
                                )}`}
                              >
                                {item.node.title}
                              </Link>
                            </h4>
                          </div>
                        </div>
                      </div>
                    )
                  })}

              <p>
                We have studied our craft and built a strong foundation in
                architecture education. We draw from European traditions and
                contemporary and modern design coupled state of the art
                technologies in eco-friendly green design.
              </p>

              <p>
                Our experience in the field helps us with all the details of
                project management and delivery. We know where to look and find
                answers. We get the job done!
              </p>

              <p>
                Let us know what sort of home construction project you have in
                mind and let’s make it a reality.
              </p>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Projects

export const projectsQuery = graphql`
  query projectsQuery {
    allSanityProject {
      edges {
        node {
          id
          body
          title
          subheading
          mainImage {
            alt
            asset {
              fixed(width: 355, height: 355, toFormat: JPG) {
                src
              }
            }
          }
          imageGallery {
            alt
            asset {
              fluid {
                src
              }
            }
          }
          tag {
            title
          }
        }
      }
      group(field: tag___title) {
        fieldValue
        edges {
          node {
            id
            title
            mainImage {
              alt
              asset {
                fixed(width: 355, height: 355, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`
